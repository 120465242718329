<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card class="rounded-lg" :loading="loading" :disabled="loading">
      <v-card-title class="headline primary white--text">
        Edit Policy
      </v-card-title>
      <v-card-text class="pt-3">
        <v-form ref="memberForm">
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="name">
                First Name


              </label>
              <v-text-field v-model="editMember.firstName" outlined dense />
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium" for="midlename">
                Middle Name
              </label>
              <v-text-field v-model="editMember.midleName" outlined dense />
            </v-col>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="lastname">
                Last Name


              </label>
              <v-text-field v-model="editMember.lastName" class="rounded" outlined dense></v-text-field>
            </v-col>

          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="dateofbirth">Date of Birth

              </label>
              <ma-date-picker v-model="editMember.birthDate" past :editable="true" />
            </v-col>

            <v-col cols="12" sm="4" class="px-1">
              <label for="phone">Phone </label>
              <v-text-field v-model="editMember.homePhone" :rules="[rules.phone1]" prepend-inner-icon="mdi-phone"
                autocomplete="tel" outlined dense />
            </v-col>
          </v-row>
          <v-row no-gutters>

            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="gender">Gender

              </label>
              <v-select :items="genders" item-text="label" item-value="value" v-model="editMember.isMale" outlined
                dense></v-select>
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium" for="legal">Legal Status </label>
              <v-select :items="legalstatus" v-model="editMember.legalStatus" item-text="label" item-value="value" dense
                outlined></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="cernatur">ID Number </label>
              <br />

              <v-text-field outlined v-model="editMember.idNumber" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="cernatur">Company<span class="red--text">*</span>
              </label>
              <br />
              <v-select :items="companies" v-model="companySelect" :loading="loadingRefer" item-text="name"
                item-value="id" outlined dense></v-select>
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label :class="'font-weight-medium'" for="cernatur">Company State<span class="red--text">*</span>
              </label>
              <br />
              <v-autocomplete :items="companyStates" :loading="loadingCompanyStates"
                :disabled="companySelect == null || companyStates.length == 0" v-model="editMember.companyStateId"
                item-text="state.name" item-value="state.id" outlined dense></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium" for="source">Binder Pay </label>
              <v-radio-group v-model="editMember.binderDay" class="mt-0" mandatory row dense hide-details>
                <v-radio :value="true" :label="$t('Yes')" />
                <v-radio :value="false" :label="$t('No')"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="cernatur">Policy Number
              </label>
              <br />

              <v-text-field v-model="editMember.polizeNumber" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium" for="source">Effective Date
              </label>
              <ma-date-picker v-model="editMember.effectiveDate" past :editable="true" />
            </v-col>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium">Metal Plan
              </label>
              <br />

              <v-select :items="metalplan" item-text="label" item-value="value" v-model="editMember.policyPlan" outlined
                dense></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium">Premium </label>
              <v-text-field outlined dense v-model="editMember.premium"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium" for="source">Members </label>
              <v-text-field v-model="editMember.members" outlined dense></v-text-field></v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-alert class="mx-4" type="error" :value="!validForm">
        All fields with ( * ) are required
      </v-alert>
      <v-divider class="mb-3" />

      <v-card-actions class="justify-end">
        <v-btn color="error" :disabled="loading" width="100" dark depressed rounded @click="cancel">
          Cancel
        </v-btn>
        <!--      <v-btn
        color="grey"
        :disabled="loading"
        width="100"
        dark
        depressed
        rounded
        @click="$refs.patientForm.reset()"
      >
        Reset
      </v-btn> -->
        <v-btn class="ml-3" color="primary" :disabled="loading || !validForm" :loading="loading" width="100" depressed
          rounded @click="update">
          Edit
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import rules from "@/components/account/rules";

import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { getAPI } from "@/api/axios-base";
import { mapActions, mapState } from 'vuex';

export default {
  name: "update-members",
  components: { MaDatePicker },
  data() {
    return {
      rules: {
        required: rules.required,
        email: rules.email,
        zip: rules.isZipCode,
        phone: rules.isPhone,
        phone1: rules.isPhone1,
      },
      loading: false,
      memberUuid: null,
    
      loadingCompanyStates: false,
      companySelect: null,
      companyStates: [],
      editMember: {
        firstName: "",
        lastName: "",
        midleName: "",
        
        homePhone:'',
        birthDate: "",
        isMale: false,
        legalStatus: "CITIZEN",
        idNumber: "",
        isAplicant: true,
        clientUuid: "",
        companyId: null,
        companyStateId: null,
        policyPlan: "SILVER",
        polizeNumber: "",
        binderDay:false,
        effectiveDate:null,
        premium:0,
        members:0
      },
      genders: [
        { label: "Female", value: false },
        { label: "Male", value: true },
      ],
      metalplan: [
      { label: "Bronze", value: "BRONZE" },
      { label: "Silver", value: "SILVER" },
      { label: "Gold", value: "GOLD" },
      ],
      legalstatus: [
        { label: "Citizen", value: "CITIZEN" },
        { label: "Permanent Resident", value: "PERMANENT_RESIDENT" },
        { label: "Temporal Resident", value: "TEMPORAL_RESIDENT" },
      ],
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loadingRefer", "companies"]),
    validWorkPrimary() {
      const path = /^[1-9][0-9]{9}$/;
      if (
        this.editMember.workPhone == null ||
        this.editMember.workPhone == ""
      ) {
        return true;
      }
      if (path.test(this.editMember.workPhone)) {
        return true;
      }
      return false;
    },
    validForm() {
    if (
    this.editMember.companyId == null ||
    this.editMember.companyId == "" ||
    this.editMember.companyStateId == null ||
    this.editMember.companyStateId == ""
    ) {
    return false;
    }
    return true;
    
    },
  },
  watch: {
    companySelect(val) {
      if (val != null) {
        this.editMember.companyId = val;
        this.getCompanyState(val);
      }
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListCompaniesNom"]),
    cancel() {
      const uuid = this.$route.params.uuid;
      this.$router.push(`/clients/details/${uuid}`);
    },
    update() {
      let body = Object.assign({}, this.editMember);
      const uuid = this.$route.params.uuid;
    if (body.homePhone) {
      body.homePhone = "+1" + body.homePhone;
      }
      if (body.premium) {
      body.premium =Number(body.premium);
        }
        if (body.members) {
        body.members =Number(body.members);
      }
      if (body.premium==0||body.premium==null||body.premium==''||body.premium==undefined) {
      delete body.premium;
      }

      body = this.cleanNull(body);
      this.loading = true;
      getAPI
        .put("/members/update/" + this.memberUuid, body)
        .then((res) => {
          this.loading = false;
          notifyInfo("Policy has been updated");
          this.$router.push(`/clients/details/${uuid}`);
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    getCompanyState(id) {
      this.loadingCompanyStates = true;
      getAPI
        .post("/company-state-price/filterList", { companyId: id })
        .then((res) => {
          this.loadingCompanyStates = false;
          this.companyStates = res.data;
        })
        .catch((error) => {
          this.loadingCompanyStates = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },

    async init() {
      let mem = localStorage.getItem("member");
      mem = atob(mem);
      mem = JSON.parse(mem);

      this.memberUuid = mem.uuid;
      
      await this.actListCompaniesNom();
      this.companySelect = mem.companyId
      this.editMember = {
        companyStateId: mem.companyState?.id,
        policyPlan: mem.policyPlan,
        polizeNumber: mem.polizeNumber,
        binderDay:mem.binderDay,
        effectiveDate:mem.effectiveDate ? mem.effectiveDate.split("T")[0] : "",
        premium:mem.premium,
        members:mem.members,
        isAplicant: mem.isAplicant,
        firstName: mem.firstName,
        lastName: mem.lastName,
        midleName: mem.midleName,
        birthDate: mem.birthDate,
        isMale: mem.isMale,
        legalStatus: mem.legalStatus,
        idNumber: mem.idNumber,
        homePhone: mem.homePhone
          ? mem.homePhone.toString().replace("+1", "")
          : "",
       
      };
    },
  },
  async mounted() {
    
    this.init();
  },
  destroyed() {
    //  localStorage.removeItem("member");
  },
};
</script>
<style lang="scss" scoped>
.cerofnat {
  padding-top: 2px !important;
}
</style>
